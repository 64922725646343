// Name:            Close
// Description:     Component to create a close button
//
// Component:       `uk-close`
//
// ========================================================================


// Variables
// ========================================================================



/* ========================================================================
   Component: Close
 ========================================================================== */

/*
 * Adopts `uk-icon`
 */

.uk-close {
    color: $close-color;
    @if(mixin-exists(hook-close)) {@include hook-close();}
}

/* Hover */
.uk-close:hover {
    color: $close-hover-color;
    @if(mixin-exists(hook-close-hover)) {@include hook-close-hover();}
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-close-misc)) {@include hook-close-misc();}

// @mixin hook-close(){}
// @mixin hook-close-hover(){}
// @mixin hook-close-misc(){}


// Inverse
// ========================================================================




// @mixin hook-inverse-close(){}
// @mixin hook-inverse-close-hover(){}

// 1. Your custom variables and variable overwrites.
$global-link-color: #DA7D02;
$global-font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// 2. Import default variables and available mixins.
@import "node_modules/uikit/src/scss/variables-theme.scss";
@import "node_modules/uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
//@mixin hook-card() { color: #000; }

// 4. Import UIkit.
@import "node_modules/uikit/src/scss/uikit-theme.scss";

html, body {
  overflow-x: hidden;
}

h1 {
  font-weight: 700;
  letter-spacing: 0em;
  font-size: 3rem
}

h2 {
  font-size: 2.5rem;
}

a[disabled] {
  pointer-events: none;
  color: #aaa;
}

.uk-section-secondary {
  h2:not(.uk-preserve-color) {
    color: #dddddd;
  }
}

nav.uk-navbar {

  .uk-navbar-right {
    gap: 30px;
  }

  .uk-navbar-item, .uk-navbar-toggle {
    min-height: auto;
  }
}

.uk-slidenav {
  background-color: rgba(95, 25, 25, .85);
  border-radius: 50%;
  padding: 6px 6px;
  color: white;

  opacity: 1;
  transition: opacity .7s ease-in;
  &.uk-invisible {
    visibility: visible !important;
    opacity: 0;
  }

  & svg {
    height: 16px;
  }

  &.uk-slidenav-next {
    padding-left: 8px;
  }

  &.uk-slidenav-prev {
    padding-right: 8px;
  }

  &:hover {
    color: white;
    box-shadow: 0 0 5px #888;
  }
}

.uk-input, .uk-select, .uk-textarea, .uk-button {
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.uk-button {
  border-radius: 5px;
}

#map {
  height: 600px;
}

#video-overlay {
  h1, p {
    color: white;
    font-weight: 700;
    letter-spacing: 0;
  }

  br { display: none; }
  h1 {
    line-height: 1;
    margin: 0;
  }
  p {
    margin-top: 4px;
    line-height: 1.3;
    font-size: 22px;
    font-family: 'Roboto Condensed', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: oblique;
  }

  max-width: 600px;
  top: auto; right: 0%; bottom: 15vh; left: 0%;

  @media (min-width: $breakpoint-small) {
    top: 50%; bottom: auto;
    br { display: inline; }
    h1 { font-size: 54px; }
    p { font-size: 24px; }
  }
  @media (min-width: $breakpoint-medium) {
    h1 { font-size: 72px; }
    p { font-size: 36px; }
    max-width: 900px;
  }
}


.fix-heading-margin-on-small {
  margin-top: 20px;
  @media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium - 1) {
    margin-top: 0;
  }
}


.plans-slider {
  padding-top: $global-gutter + 20px !important;
  @media (min-width: $breakpoint-small) { padding-top: $global-small-gutter + 20px !important; }
  @media (min-width: $breakpoint-medium) { padding-top: $global-medium-gutter + 20px !important; }
  @media (min-width: $breakpoint-large) { padding-top: $global-large-gutter + 20px !important; }

  .uk-card-body p.uk-text-lead {
    font-size: 1.1rem;

    br {
      display: none;
      @media (min-width: $breakpoint-medium) { display: inline; }
      @media (min-width: $breakpoint-large) { display: none; }
    }
  }

  .uk-card-footer a.f-join-link {
    border-radius: 22px;
    color: white
  }

  .uk-slider-items > div {
    &:nth-child(1) {
      .uk-card {
        border-radius: 11px 0 0 11px;
      }
      .uk-card-header {
        border-radius: 11px 0 0 0;
        background-color: rgb(116, 46, 51);
        h3 { color: white; }
      }
      .uk-card-footer a.f-join-link {
        background-color: rgb(116, 46, 51);
      }
    }
    &:nth-child(2) {
      z-index: 1;
      .uk-card {
        border-radius: 11px;
      }
      .uk-card-header {
        border-radius: 11px 11px 0 0;
        background-color: rgb(58, 108, 85);
        h3{ color: white; }

        padding: $global-gutter; margin-top: calc(-1 * $global-gutter / 2);
        @media (min-width: $breakpoint-small) { padding: $global-gutter; margin-top: calc(-1 * $global-gutter / 2); }
        @media (min-width: $breakpoint-medium) { padding: $global-medium-gutter; margin-top: -$global-medium-gutter; }
        @media (min-width: $breakpoint-large) { padding: $global-large-gutter; margin-top: -$global-large-gutter; }
      }
      .uk-card-footer a.f-join-link {
        background-color: rgb(58, 108, 85);
      }
    }
    &:nth-child(3) {
      .uk-card {
        border-radius: 0 11px 11px 0;
      }
      .uk-card-header {
        border-radius: 0 11px 0 0;
        background-color: rgb(58, 69, 123);
        h3 { color: white; }
      }
      .uk-card-footer a.f-join-link {
        background-color: rgb(58, 69, 123);
      }
    }
  }

  .uk-slidenav,.uk-slider-nav {
    @media (min-width: $breakpoint-medium) {
      display: none;
    }
  }

}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccc !important;
}


body .uk-section:first-of-type {
  padding-top: 150px;
}

nav .uk-logo {
  position: absolute;
  top: 30px; left: 30px;
  width: 50px; height: 50px;

  @media (max-width: $breakpoint-medium) {
    position: relative;
    top: 0; left:0;
  }
}
body.home nav .uk-logo {
  width: 100px; height: 100px;
}

//body.home {
  .inline-image {
    border: 2px solid white;
    border-radius: 22px;
    position: relative;

    @media (min-width: $breakpoint-small) {
      //right: -22%;
    }
  }
//}


body.home .uk-section-default h2:not(.uk-preserve-color) {
  font-size: 3rem;
  color: #888;
  margin: 0 0 2rem 0;
}

#charities {
  margin-top: 0px;
  margin-bottom: 50px;

  .uk-padding-small {
    padding: 0 15px;
  }

  @media (min-width: $breakpoint-small) {
    margin-top: 100px;
    margin-bottom: 100px;

    padding: 15px;
  }
}



/* Sticky Table */

table.sticky-first-column {
  thead tr th:first-of-type,
  tbody tr td:first-of-type {
    position: sticky;
    position: -webkit-sticky; /* For Safari */
    left: 0;
    background-color: #FFF; /* Match your table's background */
    z-index: 2; /* Ensures the sticky column is above horizontally scrolled content */

    /* Optional: Add a shadow to the sticky column to indicate it's over other content */
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -10px;
      bottom: 0;
      width: 10px;
      background-image: linear-gradient(to right, rgba(0,0,0,0.1), transparent);
      z-index: 1;
    }
  }
}


/* ------------------------- JOIN --------------------------- */

body.join {
  input[type=radio] {
    display: none;
  }

  div.input-radio {
    margin-top: .8rem;

    label {
      display: block;
      padding: 10px;
      border-radius: 2px;
      border-color: #ccc;

      font-size: 17px;
      line-height: 1.23536;
      //font-weight: 400;
      //letter-spacing: -.022em;
      width: 100%;
      height: auto;
      border-radius: 12px;
      border-width: 1px;
      border-style: solid;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      padding: 15px;
      text-align: left;
      box-sizing: border-box;
      cursor: pointer;
      min-height: 4.88235rem;
    }

    input:checked~label {
      border-color: rgb(58, 108, 85);
      border-width: 2px;
      padding: 14px;
    }
    input:disabled~label {
      cursor: default;
      background-color: #f9f9f9;
    }
  }

  .input-appointment {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 2px;
  }


  .uk-sticky {
    &.uk-active {
      background-color: rgb(58, 69, 123);
      color: white;
      border-bottom: 1px solid #eee;
      box-shadow: rgb(75 75 75 / 30%) 0 1px 8px 0;
      margin: 0 -15px;
      padding: 20px;

      & > div {
        display: block !important;
      }
    }
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 0;

    & + p {
      margin-top: 0;
    }
  }

  ul.join-features {
    padding: 0;

    li {
      list-style-type: none;
    }

    span {
      color: green;
    }
  }

  #card-review {
    td {
      padding: 2px 12px;
    }

    table tr td:last-of-type {
      text-align: right;
    }

    tbody {
      color: #888;
    }

    tbody tr:last-of-type td { padding-bottom: 10px; }
    tfoot tr:first-of-type td { padding-top: 10px; }

    tfoot {
      font-size: 1rem;
      border-top: 1px solid #ccc;

      .gst {
        color: #aaa;
      }

      .total {
        font-weight: bolder;
        color: #444;
      }
    }
  }

  .appointment-selector {
    .uk-modal-dialog {
      max-height: 100%;
      overflow-y: hidden;

      display: flex;
      flex-direction: column;
    }

    .uk-modal-header {
      flex-shrink: 0;
      box-shadow: 0 10px 20px 5px #fff;
    }
    .uk-modal-footer {
      flex-shrink: 0;
      padding-top: 35px;
      box-shadow: 0 -10px 20px 35px #fff;
      position: relative;

      .uk-icon {
        position: absolute;
        top: -18px;
        left: 50%;
        margin-left: -18px;
        cursor: pointer;
      }
    }
    .uk-modal-body {
      //flex-grow: 1;
      overflow: auto;
    }

    .appointment-week {
      margin-bottom: 30px;

      & > .legend {
        font-size: 85%;
        text-transform: uppercase;
        margin-bottom: 12px;
        border-bottom: 1px solid #eee;
      }
    }

    .appointment-day {
      margin-bottom: 8px;
      padding: 0 10px;
      color: #888;

      .legend {
        .dayname {
          font-weight: bolder;
          color: #444;
        }
      }
    }

    .appointment-slot {
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 3px;
      padding: 6px;
      margin-bottom: 4px;
      cursor: pointer;
      width: 3em;
      display: inline-block;
      text-align: center;
      box-shadow:  rgba(138, 145, 153, 0.18) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;


      &:hover, &.selected {
        border-width: 2px;
        padding: 5px;
      }

      &:hover {
        border-color: rgb(147, 152, 173);
        color: rgb(147, 152, 173);
      }

      &.selected {
        border-color: rgb(58, 69, 123);
        color: rgb(58, 69, 123);
      }
    }
  }

  #payment-element {
    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
.show-discount {
  background-color: #fff9f9;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 0 0 2px 2px #fdd;

}
// Name:            Marker
// Description:     Component to create a marker icon
//
// Component:       `uk-marker`
//
// ========================================================================


// Variables
// ========================================================================




/* ========================================================================
   Component: Marker
 ========================================================================== */

/*
 * Addopts `uk-icon`
 */

.uk-marker {
    padding: $marker-padding;
    background: $marker-background;
    color: $marker-color;
    @if(mixin-exists(hook-marker)) {@include hook-marker();}
}

/* Hover */
.uk-marker:hover {
    color: $marker-hover-color;
    @if(mixin-exists(hook-marker-hover)) {@include hook-marker-hover();}
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-marker-misc)) {@include hook-marker-misc();}

// @mixin hook-marker(){}
// @mixin hook-marker-hover(){}
// @mixin hook-marker-misc(){}


// Inverse
// ========================================================================




// @mixin hook-inverse-marker(){}
// @mixin hook-inverse-marker-hover(){}

// Name:            Transition
// Description:     Utilities for transitions
//
// Component:       `uk-transition-*`
//
// Modifiers:       `uk-transition-fade`
//                  `uk-transition-scale-up`
//                  `uk-transition-scale-down`
//                  `uk-transition-slide-top-*`
//                  `uk-transition-slide-bottom-*`
//                  `uk-transition-slide-left-*`
//                  `uk-transition-slide-right-*`
//                  `uk-transition-opaque`
//                  `uk-transition-slow`
//                  `uk-transition-disable`
//
// Sub-objects:     `uk-transition-toggle`,
//                  `uk-transition-active`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================






/* ========================================================================
   Component: Transition
 ========================================================================== */

/* Transitions
 ========================================================================== */

/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 */

:where(.uk-transition-fade),
:where([class*="uk-transition-scale"]),
:where([class*="uk-transition-slide"]) {
    --uk-position-translate-x: 0;
    --uk-position-translate-y: 0;
}

.uk-transition-fade,
[class*="uk-transition-scale"],
[class*="uk-transition-slide"] {
    --uk-translate-x: 0;
    --uk-translate-y: 0;
    --uk-scale-x: 1;
    --uk-scale-y: 1;
    transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y))
               translate(var(--uk-translate-x), var(--uk-translate-y))
               scale(var(--uk-scale-x), var(--uk-scale-y));
    transition: $transition-duration ease-out;
    transition-property: opacity, transform, filter;
    opacity: 0;
}

/*
 * Fade
 */

.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-toggle .uk-transition-fade:focus-within,
.uk-transition-active.uk-active .uk-transition-fade { opacity: 1; }

/*
 * Scale
 * 1. Make image rendering the same during the transition as before and after. Prefixed because of Safari.
 */

/* 1 */
[class*="uk-transition-scale"] { -webkit-backface-visibility: hidden; }

.uk-transition-scale-up {
    --uk-scale-x: 1;
    --uk-scale-y: 1;
}
.uk-transition-scale-down {
    --uk-scale-x: #{$transition-scale};
    --uk-scale-y: #{$transition-scale};
}

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-toggle .uk-transition-scale-up:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-up {
    --uk-scale-x: #{$transition-scale};
    --uk-scale-y: #{$transition-scale};
    opacity: 1;
}

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-toggle .uk-transition-scale-down:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-down {
    --uk-scale-x: 1;
    --uk-scale-y: 1;
    opacity: 1;
}

/*
 * Slide
 */

.uk-transition-slide-top { --uk-translate-y: -100%; }
.uk-transition-slide-bottom { --uk-translate-y: 100%; }
.uk-transition-slide-left { --uk-translate-x: -100%; }
.uk-transition-slide-right { --uk-translate-x: 100%; }

.uk-transition-slide-top-small { --uk-translate-y: calc(-1 * #{$transition-slide-small-translate}); }
.uk-transition-slide-bottom-small { --uk-translate-y: #{$transition-slide-small-translate}; }
.uk-transition-slide-left-small { --uk-translate-x: calc(-1 * #{$transition-slide-small-translate}); }
.uk-transition-slide-right-small { --uk-translate-x: #{$transition-slide-small-translate}; }

.uk-transition-slide-top-medium { --uk-translate-y: calc(-1 * #{$transition-slide-medium-translate}); }
.uk-transition-slide-bottom-medium { --uk-translate-y: #{$transition-slide-medium-translate}; }
.uk-transition-slide-left-medium { --uk-translate-x: calc(-1 * #{$transition-slide-medium-translate}); }
.uk-transition-slide-right-medium { --uk-translate-x: #{$transition-slide-medium-translate}; }

/* Show */
.uk-transition-toggle:hover [class*="uk-transition-slide"],
.uk-transition-toggle:focus [class*="uk-transition-slide"],
.uk-transition-toggle [class*="uk-transition-slide"]:focus-within,
.uk-transition-active.uk-active [class*="uk-transition-slide"] {
    --uk-translate-x: 0;
    --uk-translate-y: 0;
    opacity: 1;
}


/* Opacity modifier
 ========================================================================== */

.uk-transition-opaque { opacity: 1; }


/* Duration modifier
 ========================================================================== */

.uk-transition-slow { transition-duration: $transition-slow-duration; }

/* Disable modifier
 ========================================================================== */

.uk-transition-disable,
.uk-transition-disable * { transition: none !important; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-transition-misc)) {@include hook-transition-misc();}

// @mixin hook-transition-misc(){}

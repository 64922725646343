// Name:            Slidenav
// Description:     Component to create previous/next icon navigations
//
// Component:       `uk-slidenav`
//
// Sub-objects:     `uk-slidenav-container`
//
// Modifiers:       `uk-slidenav-previous`
//                  `uk-slidenav-next`
//                  `uk-slidenav-large`
//
// ========================================================================


// Variables
// ========================================================================





/* ========================================================================
   Component: Slidenav
 ========================================================================== */

/*
 * Adopts `uk-icon`
 */

.uk-slidenav {
    padding: $slidenav-padding-vertical $slidenav-padding-horizontal;
    color: $slidenav-color;
    @if(mixin-exists(hook-slidenav)) {@include hook-slidenav();}
}

/* Hover */
.uk-slidenav:hover {
    color: $slidenav-hover-color;
    @if(mixin-exists(hook-slidenav-hover)) {@include hook-slidenav-hover();}
}

/* OnClick */
.uk-slidenav:active {
    color: $slidenav-active-color;
    @if(mixin-exists(hook-slidenav-active)) {@include hook-slidenav-active();}
}


/* Icon modifier
 ========================================================================== */

/*
 * Previous
 */

.uk-slidenav-previous {
    @if(mixin-exists(hook-slidenav-previous)) {@include hook-slidenav-previous();}
}

/*
 * Next
 */

.uk-slidenav-next {
    @if(mixin-exists(hook-slidenav-next)) {@include hook-slidenav-next();}
}


/* Size modifier
 ========================================================================== */

.uk-slidenav-large {
    padding: $slidenav-large-padding-vertical $slidenav-large-padding-horizontal;
    @if(mixin-exists(hook-slidenav-large)) {@include hook-slidenav-large();}
}


/* Container
 ========================================================================== */

.uk-slidenav-container {
    display: flex;
    @if(mixin-exists(hook-slidenav-container)) {@include hook-slidenav-container();}
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-slidenav-misc)) {@include hook-slidenav-misc();}

// @mixin hook-slidenav(){}
// @mixin hook-slidenav-hover(){}
// @mixin hook-slidenav-active(){}
// @mixin hook-slidenav-previous(){}
// @mixin hook-slidenav-next(){}
// @mixin hook-slidenav-large(){}
// @mixin hook-slidenav-container(){}
// @mixin hook-slidenav-misc(){}


// Inverse
// ========================================================================




// @mixin hook-inverse-slidenav(){}
// @mixin hook-inverse-slidenav-hover(){}
// @mixin hook-inverse-slidenav-active(){}

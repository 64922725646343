// Name:            Sticky
// Description:     Component to make elements sticky in the viewport
//
// Component:       `uk-sticky`
//
// Modifier:        `uk-sticky-fixed`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================




/* ========================================================================
   Component: Sticky
 ========================================================================== */

/*
 * 1. Create position context so it's t the same like when fixed.
 * 2. Create stacking context already when not sticky to have the same context
*     for position set to `sticky` and `relative`
 * 2. More robust if padding and border are used and the sticky height is transitioned
 */

.uk-sticky {
    /* 1 */
    position: relative;
    /* 2 */
    z-index: $sticky-z-index;
    /* 3 */
    box-sizing: border-box;
}

/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */

.uk-sticky-fixed {
    margin: 0 !important;
    /* 1 */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

/*
 * Faster animations
 */

.uk-sticky[class*="uk-animation-"] { animation-duration: $sticky-animation-duration; }

.uk-sticky.uk-animation-reverse { animation-duration: $sticky-reverse-animation-duration; }

/*
 * Placeholder
 * Make content clickable for sticky cover and reveal effects
 */

.uk-sticky-placeholder { pointer-events: none; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-sticky-misc)) {@include hook-sticky-misc();}

// @mixin hook-sticky-misc(){}

// Name:            Alert
// Description:     Component to create alert messages
//
// Component:       `uk-alert`
//
// Adopted:         `uk-alert-close`
//
// Modifiers:       `uk-alert-primary`
//                  `uk-alert-success`
//                  `uk-alert-warning`
//                  `uk-alert-danger`
//
// ========================================================================


// Variables
// ========================================================================








/* ========================================================================
   Component: Alert
 ========================================================================== */

.uk-alert {
    position: relative;
    margin-bottom: $alert-margin-vertical;
    padding: $alert-padding $alert-padding-right $alert-padding $alert-padding;
    background: $alert-background;
    color: $alert-color;
    @if(mixin-exists(hook-alert)) {@include hook-alert();}
}

/* Add margin if adjacent element */
* + .uk-alert { margin-top: $alert-margin-vertical; }

/*
 * Remove margin from the last-child
 */

.uk-alert > :last-child { margin-bottom: 0; }


/* Close
 * Adopts `uk-close`
 ========================================================================== */

.uk-alert-close {
    position: absolute;
    top: $alert-close-top;
    right: $alert-close-right;
    @if(mixin-exists(hook-alert-close)) {@include hook-alert-close();}
}

/*
 * Remove margin from adjacent element
 */

.uk-alert-close:first-child + * { margin-top: 0; }

/*
 * Hover
 */

.uk-alert-close:hover {
    @if(mixin-exists(hook-alert-close-hover)) {@include hook-alert-close-hover();}
}


/* Style modifiers
 ========================================================================== */

/*
 * Primary
 */

.uk-alert-primary {
    background: $alert-primary-background;
    color: $alert-primary-color;
    @if(mixin-exists(hook-alert-primary)) {@include hook-alert-primary();}
}

/*
 * Success
 */

.uk-alert-success {
    background: $alert-success-background;
    color: $alert-success-color;
    @if(mixin-exists(hook-alert-success)) {@include hook-alert-success();}
}

/*
 * Warning
 */

.uk-alert-warning {
    background: $alert-warning-background;
    color: $alert-warning-color;
    @if(mixin-exists(hook-alert-warning)) {@include hook-alert-warning();}
}

/*
 * Danger
 */

.uk-alert-danger {
    background: $alert-danger-background;
    color: $alert-danger-color;
    @if(mixin-exists(hook-alert-danger)) {@include hook-alert-danger();}
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-alert-misc)) {@include hook-alert-misc();}

// @mixin hook-alert(){}
// @mixin hook-alert-close(){}
// @mixin hook-alert-close-hover(){}
// @mixin hook-alert-primary(){}
// @mixin hook-alert-success(){}
// @mixin hook-alert-warning(){}
// @mixin hook-alert-danger(){}
// @mixin hook-alert-misc(){}

// Name:            Tooltip
// Description:     Component to create tooltips
//
// Component:       `uk-tooltip`
//
// Modifiers        `uk-tooltip-top`
//                  `uk-tooltip-top-left`
//                  `uk-tooltip-top-right`
//                  `uk-tooltip-bottom`
//                  `uk-tooltip-bottom-left`
//                  `uk-tooltip-bottom-right`
//                  `uk-tooltip-left`
//                  `uk-tooltip-right`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================




/* ========================================================================
   Component: Tooltip
 ========================================================================== */

/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */

.uk-tooltip {
    /* 1 */
    display: none;
    /* 2 */
    position: absolute;
    z-index: $tooltip-z-index;
    --uk-position-offset: #{$tooltip-margin};
    --uk-position-viewport-offset: 10;
    /* 3 */
    top: 0;
    /* 4 */
    box-sizing: border-box;
    max-width: $tooltip-max-width;
    padding: $tooltip-padding-vertical $tooltip-padding-horizontal;
    /* 5 */
    background: $tooltip-background;
    border-radius: $tooltip-border-radius;
    color: $tooltip-color;
    font-size: $tooltip-font-size;
    @if(mixin-exists(hook-tooltip)) {@include hook-tooltip();}
}

/* Show */
.uk-tooltip.uk-active { display: block; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-tooltip-misc)) {@include hook-tooltip-misc();}

// @mixin hook-tooltip(){}
// @mixin hook-tooltip-misc(){}

// Name:            Totop
// Description:     Component to create an icon to scroll back to top
//
// Component:       `uk-totop`
//
// ========================================================================


// Variables
// ========================================================================





/* ========================================================================
   Component: Totop
 ========================================================================== */

/*
 * Addopts `uk-icon`
 */

.uk-totop {
    padding: $totop-padding;
    color: $totop-color;
    @if(mixin-exists(hook-totop)) {@include hook-totop();}
}

/* Hover */
.uk-totop:hover {
    color: $totop-hover-color;
    @if(mixin-exists(hook-totop-hover)) {@include hook-totop-hover();}
}

/* OnClick */
.uk-totop:active {
    color: $totop-active-color;
    @if(mixin-exists(hook-totop-active)) {@include hook-totop-active();}
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-totop-misc)) {@include hook-totop-misc();}

// @mixin hook-totop(){}
// @mixin hook-totop-hover(){}
// @mixin hook-totop-active(){}
// @mixin hook-totop-misc(){}


// Inverse
// ========================================================================




// @mixin hook-inverse-totop(){}
// @mixin hook-inverse-totop-hover(){}
// @mixin hook-inverse-totop-active(){}
